import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import * as Yup from 'yup'
import { useTranslation } from 'next-i18next'
import { useLocale } from 'hooks/locales/useLocale'
import { useFormik } from 'formik'
// hooks
import { useMailchimp } from 'hooks/mailchimp/useMailchimp'
// components
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Input } from '@vizeat/components/es6/components/Input'
import { Text } from '@vizeat/components/es6/components/Text'
// helpers
import settings from 'settings'
import { saveCookie, COOKIE_KEYS } from 'helpers/cookies'

export function MemberSubscriptionForm({
  collectionType,
  onSuccess,
  placeholderText,
  inputForwardedStyles,
  submitButton,
  ...props
}) {
  const { t } = useTranslation()
  const { locale } = useLocale()
  const { error, subscriptionSucceeded, subscribeMember, INTERESTS } = useMailchimp(settings.mailchimpAudienceId) // used the Eatwith Emailing audience id
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(t('FormField::Wrong format')).required(t('FormField::Required field')),
    }),
    onSubmit: ({ email }) => {
      subscribeMember(email, {
        merge_fields: {
          USER_TYPE: 'guest',
          USER_LANG: locale.toLowerCase(),
          C_SOURCE: collectionType,
          U_COUNTRY: undefined,
          EMAIL: email,
        },
        interests: {
          [INTERESTS.PLATFORM_SOURCE_EATWITH]: true,
          [INTERESTS.NOTIFICATION_PREFERENCES_GLOBAL_NEWSLETTER]: true,
          [INTERESTS.NOTIFICATION_PREFERENCES_MONTHLY_HOST_NEWSLETTER]: true,
        },
      })
    },
  })

  useEffect(() => {
    if (subscriptionSucceeded) {
      saveCookie({
        name: COOKIE_KEYS.NEWSLETTER_SIGNUP,
        value: true,
        duration: 365 * 24 * 3600,
      })
      formik.resetForm()
      onSuccess?.()
    }
  }, [subscriptionSucceeded, onSuccess, formik])

  return (
    <Flex flexDirection='column'>
      <Grid as='form' gap='16px' onSubmit={formik.handleSubmit} mb='8px' {...props}>
        <Box
          css={`
            div[class^='Input__Container'] {
              margin-top: 0;
            }
          `}
        >
          <Input
            isRequired
            type='email'
            name='email'
            value={formik.values.email}
            placeholder={placeholderText}
            onChange={formik.handleChange}
            css={inputForwardedStyles}
          />
        </Box>
        {submitButton}
      </Grid>
      {subscriptionSucceeded && (
        <Box textAlign='center' my='8px'>
          <Text as='span' color='success'>
            {t("MemberSubscriptionForm::You've successfully subscribed to our newsletter.")}
          </Text>
        </Box>
      )}
      {error && (
        <Box textAlign='center' my='8px'>
          <Text as='span' color='error'>
            {error}
          </Text>
        </Box>
      )}
      {formik.errors.email && (
        <Box textAlign='center' my='8px'>
          <Text as='span' color='error'>
            {t(formik.errors.email)}
          </Text>
        </Box>
      )}
    </Flex>
  )
}

MemberSubscriptionForm.propTypes = {
  collectionType: PropTypes.string,
  placeholderText: PropTypes.string,
  submitButton: PropTypes.node.isRequired,
  inputForwardedStyles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
  ]),
  onSuccess: PropTypes.func,
}

MemberSubscriptionForm.defaultProps = {
  collectionType: 'newsletter_signup',
  placeholderText: undefined,
  inputForwardedStyles: 'padding: 16px;',
  onSuccess: undefined,
}
