import { screenApi } from 'apis'

export async function createBooking(payload) {
  const { data } = await screenApi.post(`/bookings`, payload)
  return data
}

export async function updateBooking(id, payload) {
  const { data } = await screenApi.put(`/bookings/${id}`, payload)
  return data
}

export async function notifyCoguest(bookingId, payload) {
  const { data } = await screenApi.post(`/bookings/${bookingId}/coguests`, payload)
  return data.coguests
}
