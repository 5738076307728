import { useMutation } from '@tanstack/react-query'
import { createBooking, notifyCoguest, updateBooking } from 'api/booking'
import { screenApi } from 'apis'

export function useBookingCreate(reactQueryOptions) {
  return useMutation({
    mutationFn: ({ payload, idempotencyKey }) => {
      if (idempotencyKey) screenApi.setHeader('X-Idempotency-Key', idempotencyKey)
      return createBooking(payload)
    },
    ...reactQueryOptions,
  })
}

export function useBookingUpdate(reactQueryOptions) {
  return useMutation({
    mutationFn: ({ id, payload }) => updateBooking(id, payload),
    ...reactQueryOptions,
  })
}

export function useNotifyCoguests(reactQueryOptions) {
  return useMutation({
    mutationFn: ({ bookingId, payload }) => notifyCoguest(bookingId, { coguests: payload }),
    ...reactQueryOptions,
  })
}
