// Libraries
import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSocialMedia } from 'hooks/socials/useSocialMedia'
// Components
import { ApiErrorMessage } from 'components/shared/messages'
import { FacebookLoginButton, GoogleLoginButton } from 'components/shared/buttons'
import { Button } from '@vizeat/components/es6/components/Button'
import { ButtonLink } from 'components/shared/ButtonLink'
import { ButtonWithLoader } from '@vizeat/components/es6/components/ButtonWithLoader'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Input } from '@vizeat/components/es6/components/Input'
import { Text } from '@vizeat/components/es6/components/Text'
// Actions
import { loginWithEmail, toggleLoginModal, toggleRegisterModal } from 'redux/actions'
import { loggingIn, getLoginError } from 'redux/selectors'
// Helpers
import { urlFactory } from 'helpers/url'
// gtm
import { gtmVisitorSawAuthenticationForm } from 'gtm'

LoginForm.propTypes = {
  gtmAppearance: PropTypes.oneOf(['modal', 'page']).isRequired,
}

export function LoginForm({ gtmAppearance }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isLoading = useSelector(loggingIn)
  const loginError = useSelector(getLoginError)
  const { isSocialAuthEnabled } = useSocialMedia()

  useEffect(() => {
    gtmVisitorSawAuthenticationForm({ type: 'login', appearance: gtmAppearance })
  }, [gtmAppearance])

  function handleSubmit({ email, password, rememberMe }) {
    dispatch(loginWithEmail({ email, password, rememberMe }))
  }

  function handleCloseLoginModal() {
    dispatch(toggleLoginModal({ show: false }))
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: true,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(t('FormField::Wrong format')).required(t('Account::Please enter your email')),
      password: Yup.string().required(t('Account::Please enter your password')),
      rememberMe: Yup.bool(),
    }),
    onSubmit: handleSubmit,
  })

  return (
    <div>
      {isSocialAuthEnabled && (
        <Grid gap='16px' templateColumns={{ tablet: 'repeat(2, 1fr)' }}>
          <FacebookLoginButton>{t('Account::Log in with Facebook')}</FacebookLoginButton>
          <GoogleLoginButton>{t('Account::Log in with Google')}</GoogleLoginButton>
        </Grid>
      )}

      <form onSubmit={formik.handleSubmit} name='loginForm'>
        <Input
          autoFocus
          error={formik.touched.email && formik.errors.email}
          isRequired
          label={t('Account::Your email')}
          onChange={formik.handleChange}
          placeholder={t('Account::Email')}
          type='email'
          name='email'
          value={formik.values.email}
        />

        <Input
          error={formik.touched.password && formik.errors.password}
          isRequired
          label={t('Account::Password')}
          onChange={formik.handleChange}
          placeholder={t('Account::Password')}
          type='password'
          name='password'
          value={formik.values.password}
        />

        <Checkbox
          label={t('Account::Remember me')}
          name='rememberMe'
          onChange={formik.handleChange}
          checked={formik.values.rememberMe}
        />

        {!!loginError && <ApiErrorMessage error={loginError} />}

        <ButtonWithLoader green type='submit' loading={isLoading} fullWidth css={{ margin: '16px 0px' }}>
          {t('Account::Login')}
        </ButtonWithLoader>

        <ButtonLink clearPrimary href={urlFactory.dashboard.forgottenPassword()} onClick={handleCloseLoginModal}>
          {t('Account::Forgot your password?')}
        </ButtonLink>

        <hr />

        <Text>
          {t("Account::Don't have an account?")}{' '}
          <Button clearPrimary onClick={() => dispatch(toggleRegisterModal(true))}>
            {t('Account::Sign up now!')}
          </Button>
        </Text>
      </form>
    </div>
  )
}
