import settings from 'settings'
import { useState } from 'react'

export function useMailchimp(audienceId) {
  const [error, setError] = useState()
  const [subscriptionSucceeded, setSubscriptionSucceeded] = useState(false)

  async function subscribeMember(email, payload) {
    const response = await fetch(`${settings.cloudFlareWorkersApi}subscribe/${audienceId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email_address: email,
        status: 'subscribed',
        ...payload,
      }),
    })

    if (response.ok) {
      setError()
      setSubscriptionSucceeded(true)
      return
    }

    setSubscriptionSucceeded(false)
    const json = await response.json()
    return setError(json.message)
  }

  return {
    error,
    subscriptionSucceeded,
    subscribeMember,
    INTERESTS: {
      PLATFORM_SOURCE_EATWITH: 'c6446fee2d',
      NOTIFICATION_PREFERENCES_GLOBAL_NEWSLETTER: 'f46f83f56b',
      NOTIFICATION_PREFERENCES_MONTHLY_HOST_NEWSLETTER: 'ffd69f02d8',
      NEWSLETTER_SUBSCRIPTION_SUBSCRIBE: '7605bbcbc0',
    },
  }
}
