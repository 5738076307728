import React from 'react'
import PropTypes from 'helpers/proptypes'
import { Alert } from '@vizeat/components/es6/components/Alert'
import { ensureJSContent } from 'helpers/legacy'

export function ApiErrorMessage({ error }) {
  if (!error) return null
  const jsError = ensureJSContent(error)
  const errorMessage = jsError.message || jsError.data?.message || jsError.data

  return (
    <Alert type='error'>
      <Alert.Title>{errorMessage}</Alert.Title>
    </Alert>
  )
}

ApiErrorMessage.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string,
    }),
    PropTypes.shape({
      data: PropTypes.oneOfType([PropTypes.shape({ message: PropTypes.string }), PropTypes.string]),
    }),
  ]),
}

ApiErrorMessage.defaultProps = {
  error: undefined,
}
