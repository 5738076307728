// libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'next-i18next'
import styled from 'styled-components'
// custom hooks
import { useSocialMedia } from 'hooks/socials/useSocialMedia'
import { useHasLoadingSucceeded } from 'hooks'
// components
import { Alert } from '@vizeat/components/es6/components/Alert'
import { Text } from '@vizeat/components/es6/components/Text'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Divider } from '@vizeat/components/es6/components/Divider'
import { FacebookLoginButton, GoogleLoginButton } from 'components/shared/buttons'
import { RegistrationForm } from './RegistrationForm'
import { SmartLink } from 'components/shared/SmartLink'
import { Button } from '@vizeat/components/es6/components/Button'
// redux
import { registerWithEmail, updateCurrentUser, toggleLoginModal } from 'redux/actions'
import {
  registeringUser,
  getRegisterError,
  updatingCurrentUsers,
  getUpdateCurrentUserError,
  getRegisterFormType,
} from 'redux/selectors'
// helpers
import { removeEmptyKeysOfObject } from 'helpers/forms'
import { COOKIE_KEYS, loadCookie, saveCookie } from 'helpers/cookies'
import { apiErrorCodes as API_ERROR_CODES } from '@vizeat/helpers'
// gtm
import { gtmVisitorSawAuthenticationForm } from 'gtm'

const PolicyText = styled(Text)`
  line-height: 12px;

  a {
    color: ${({ theme }) => theme.colors.mediumGray};
  }
`

function ErrorAlert({ error, fallbackMessage }) {
  if (!error) return null
  const errorMessage = error.getIn && (error.getIn(['data', 'localized_message']) || error.getIn(['data', 'message']))
  return (
    <Alert type='error'>
      <Alert.Title>{errorMessage || fallbackMessage}</Alert.Title>
    </Alert>
  )
}

ErrorAlert.propTypes = {
  error: PropTypes.immutable.map,
  fallbackMessage: PropTypes.string,
}

ErrorAlert.defaultProps = {
  error: undefined,
  fallbackMessage: undefined,
}

export function SignUpForm({ gtmAppearance, onSuccess, showPlace }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const formType = useSelector(getRegisterFormType) || 'email'
  const isRegisteringUser = useSelector(registeringUser)
  const isUpdatingCurrentUser = useSelector(updatingCurrentUsers)
  const registerError = useSelector(getRegisterError)
  const updateCurrentUserError = useSelector(getUpdateCurrentUserError)

  const { isSocialAuthEnabled } = useSocialMedia()

  const hasRegisteredUser = useHasLoadingSucceeded(isRegisteringUser, registerError)
  const hasUpdatedCurrentUser = useHasLoadingSucceeded(isUpdatingCurrentUser, updateCurrentUserError)

  const [isUserUnderage, setIsUserUnderage] = useState()

  useEffect(() => {
    gtmVisitorSawAuthenticationForm({ type: 'signup', appearance: gtmAppearance })
  }, [gtmAppearance])

  useEffect(() => {
    if (hasRegisteredUser || hasUpdatedCurrentUser) {
      saveCookie({ name: COOKIE_KEYS.NEW_USER, value: true })
      if (onSuccess) onSuccess()
    }
  }, [dispatch, hasRegisteredUser, hasUpdatedCurrentUser, onSuccess])

  function handleOpenLoginModal() {
    dispatch(toggleLoginModal({ show: true }))
  }

  useEffect(() => {
    if (
      registerError &&
      registerError.getIn &&
      registerError.getIn(['data', 'code']) === API_ERROR_CODES.USER_UNDERAGED
    ) {
      setIsUserUnderage(true)
    }
  }, [registerError, setIsUserUnderage])

  // Function must be async so formik handles gracefully the end of isSubmitting state
  async function submitForm(values) {
    if (isUserUnderage) return undefined
    if (values.type === 'email') {
      return dispatch(
        registerWithEmail({
          ...values,
          affiliation_code: loadCookie(COOKIE_KEYS.AFFID),
          affiliation_code_clicked_at: loadCookie(COOKIE_KEYS.AFFID_CLICKED_AT),
          affiliation_ta_click_id: loadCookie(COOKIE_KEYS.TA_CLICK_ID),
          affiliation_ta_click_id_clicked_at: loadCookie(COOKIE_KEYS.TA_CLICK_ID_CLICKED_AT),
          tos: true,
        }),
      )
    } else {
      return dispatch(updateCurrentUser(removeEmptyKeysOfObject(values)))
    }
  }

  return (
    <>
      {formType === 'email' && (
        <>
          <Flex flexDirection='column' justifyContent='center' alignItems='flex-start' mb='24px'>
            <Divider width='100%' mt='0' mb='16px' color='lightGray' />
            <div>
              <Text as='span' type='attention'>
                {t('SignUp::Already have an account?')}
              </Text>
              <Button clearPrimary onClick={handleOpenLoginModal} css={{ marginLeft: '8px' }}>
                {t('SignUp::Log in now')}
              </Button>
            </div>
            <Divider width='100%' mt='16px' mb='0' color='lightGray' />
          </Flex>
          {isSocialAuthEnabled && (
            <Grid gap='16px' templateColumns={{ tablet: 'repeat(2, 1fr)', default: '1fr' }} mb='16px'>
              <FacebookLoginButton>{t('SignUp::Sign up with Facebook')}</FacebookLoginButton>
              <GoogleLoginButton>{t('SignUp::Sign up with Google')}</GoogleLoginButton>
            </Grid>
          )}
          <Flex justifyContent='center'>
            <Text as='span' type='attention' color='mediumGray'>
              {t('SignUp::or')}
            </Text>
          </Flex>
        </>
      )}

      <RegistrationForm
        handleSubmit={submitForm}
        isFormDisabled={isUserUnderage}
        errorMessages={
          <>
            {isUserUnderage ? (
              <ErrorAlert
                error={{}}
                fallbackMessage={t('SignUp::Sorry, but you must be at least {{age}} to join the platform', {
                  age: 18,
                })}
              />
            ) : (
              <ErrorAlert
                error={registerError}
                fallbackMessage={t('SignUp::Something wrong occurred, your account could has not been created')}
              />
            )}

            <ErrorAlert
              error={updateCurrentUserError}
              fallbackMessage={t('SignUp::Something wrong occurred, your account could has not been created')}
            />
          </>
        }
        showPlace={showPlace}
      />

      <PolicyText size='xs' type='secondary'>
        <Trans
          i18nKey={__(
            'SignUp::Privacy::The collected data is used by Vizeat Ltd in order to process your account creation, manage your bookings, personalize your online experience and for marketing purposes should you have given your consent. In accordance with the General Data Protection Rules 2018 regarding personal data protection and the Eatwith Privacy Policy, you have the right to access, rectify or ask for the deletion of your data by writing to <privacyURL>{{emailAddress}}</privacyURL>.',
          )}
          components={{ privacyURL: <SmartLink href='mailto:privacy@eatwith.com' /> }}
          values={{ emailAddress: 'privacy@eatwith.com' }}
        />
      </PolicyText>
    </>
  )
}

SignUpForm.propTypes = {
  gtmAppearance: PropTypes.oneOf(['modal', 'page']).isRequired,
  onSuccess: PropTypes.func,
  showPlace: PropTypes.bool,
}

SignUpForm.defaultProps = {
  onSuccess: undefined,
  showPlace: true,
}
