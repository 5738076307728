// Libraries
import React, { useState, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation, Trans } from 'next-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
// Components
import { AddressAutoCompleteInput } from 'components/shared/inputs'
import { ButtonWithLoader } from '@vizeat/components/es6/components/ButtonWithLoader'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox'
import { Input } from '@vizeat/components/es6/components/Input'
import { Text } from '@vizeat/components/es6/components/Text'
import { SmartLink } from 'components/shared/SmartLink'
import { Box } from '@vizeat/components/es6/components/Box'
// Redux
import { getCurrentUser, getRegisterFormType } from 'redux/selectors'

export function RegistrationForm({ errorMessages, handleSubmit, isFormDisabled, showPlace }) {
  const { t } = useTranslation()
  const formType = useSelector(getRegisterFormType) || 'email'
  const currentUser = useSelector(getCurrentUser)

  const [formInitialValues, setFormInitialValues] = useState({
    showPlace: !!showPlace,
    birth_date: undefined,
    civility: 'OTHER',
    email: '',
    firstname: '',
    lastname: '',
    newsletter: false,
    password: undefined,
    locality: '',
    country_iso: '',
    type: 'email', // 'email', 'facebook' or 'google'
  })

  useEffect(() => {
    setFormInitialValues((state) => ({ ...state, type: formType }))
  }, [formType, setFormInitialValues])

  // A current user might exist after a registration with social network,
  useEffect(() => {
    const { id, birth_date: birthDate, civility, email, firstname, lastname, locality, newsletter } = currentUser
    if (id) {
      setFormInitialValues((state) => {
        return {
          ...state,
          birth_date: birthDate || state.birth_date,
          civility: civility || state.civility,
          email: email || state.email,
          firstname: firstname || state.firstname,
          lastname: lastname || state.lastname,
          locality: locality || state.locality,
          country_iso: currentUser.country_iso || state.country_iso,
          newsletter: newsletter || state.newsletter,
        }
      })
    }
  }, [currentUser, setFormInitialValues])

  const formValidationSchema = Yup.object().shape({
    showPlace: Yup.boolean(), // Must be added to schema for conditional validation
    type: Yup.string().oneOf(['email', 'facebook', 'google']),
    civility: Yup.string(),
    firstname: Yup.string().required(t('FormField::Required field')),
    lastname: Yup.string().required(t('FormField::Required field')),
    locality: Yup.string().when('showPlace', {
      is: true,
      then: Yup.string().required(t('FormField::Required field')),
    }),
    country_iso: Yup.string().when('showPlace', {
      is: true,
      then: Yup.string().required(t('FormField::Required field')),
    }),
    email: Yup.string().email(t('FormField::Wrong format')).required(t('FormField::Required field')),
    password: Yup.string().when('type', {
      is: (type) => type === 'email',
      then: Yup.string().required(t('FormField::Required field')),
      otherwise: Yup.string().notRequired(),
    }),
    newsletter: Yup.bool(),
  })

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    enableReinitialize: true, // form updates if initialValues change
    onSubmit: isFormDisabled ? () => undefined : handleSubmit,
  })

  function handlePlaceSelect(place) {
    formik.setFieldValue('locality', place.locality)
    formik.setFieldValue('country_iso', place.country_iso)
  }

  return (
    <form autoComplete='nope' onSubmit={formik.handleSubmit} name='registerForm'>
      <Input
        autoComplete='nope'
        error={formik.touched.firstname && formik.errors.firstname && t(formik.errors.firstname)}
        isRequired
        label={t('SignUp::First name')}
        name='firstname'
        onChange={formik.handleChange}
        placeholder={t('SignUp::First name')}
        value={formik.values.firstname}
      />
      <Input
        autoComplete='nope'
        error={formik.touched.lastname && formik.errors.lastname && t(formik.errors.lastname)}
        isRequired
        label={t('SignUp::Last name')}
        name='lastname'
        onChange={formik.handleChange}
        placeholder={t('SignUp::Last name')}
        value={formik.values.lastname}
      />
      {showPlace && (
        <AddressAutoCompleteInput
          error={
            (formik.touched.locality && formik.errors.locality && t(formik.errors.locality)) ||
            (formik.touched.country_iso && formik.errors.country_iso && t(formik.errors.country_iso))
          }
          label={t('SignUp::Your city')}
          isRequired
          placeholder={t('SignUp::Your city')}
          onSelect={handlePlaceSelect}
          type='city'
          name='place'
        />
      )}

      <Input
        autoComplete='nope'
        error={formik.touched.email && formik.errors.email && t(formik.errors.email)}
        isRequired
        label={t('SignUp::Email address')}
        name='email'
        onChange={formik.handleChange}
        placeholder={t('SignUp::Email address')}
        type='email'
        value={formik.values.email}
      />

      {/* If not email, the user registered via social network and doesn't have password */}
      {formik.values.type === 'email' && (
        <Input
          autoComplete='nope'
          error={formik.touched.password && formik.errors.password && t(formik.errors.password)}
          isRequired
          label={t('SignUp::Password')}
          name='password'
          onChange={formik.handleChange}
          placeholder={t('SignUp::Password')}
          type='password'
          value={formik.values.password}
        />
      )}

      <Box mb='16px'>
        <Checkbox
          label={t(
            'SignUp::Yes! I want to receive exclusive Eatwith offers, travel inspo and all of the food {{smileEmoji}}',
            { smileEmoji: '😋' },
          )}
          name='newsletter'
          onChange={formik.handleChange}
          value={formik.values.newsletter}
        />
      </Box>

      {errorMessages}

      <ButtonWithLoader
        disabled={isFormDisabled}
        fullWidth
        green
        loading={formik.isSubmitting}
        onClick={formik.handleSubmit}
        type='submit'
      >
        {formik.values.type === 'email' ? t('SignUp::Sign me up!') : t('SignUp::Continue')}
      </ButtonWithLoader>

      <Text size='s' type='secondary'>
        <Trans
          i18nKey={__(
            "SignUp::By signing up, I agree to VizEat's <termsAndConditionsURL><boldText>Terms & Conditions</boldText></termsAndConditionsURL>, <trustURL><boldText>Trust</boldText></trustURL> and <privacyPolicyURL><boldText>Privacy Policy</boldText></privacyPolicyURL>",
          )}
          components={{
            termsAndConditionsURL: <SmartLink href='/terms' />,
            boldText: <Text as='span' size='s' type='attention' color='mediumGray' />,
            trustURL: <SmartLink href='/trust' />,
            privacyPolicyURL: <SmartLink href='/privacy' />,
          }}
        />
      </Text>
    </form>
  )
}

RegistrationForm.propTypes = {
  errorMessages: PropTypes.node,
  handleSubmit: PropTypes.func.isRequired,
  isFormDisabled: PropTypes.bool,
  showPlace: PropTypes.bool,
}

RegistrationForm.defaultProps = {
  errorMessages: undefined,
  showPlace: true,
  isFormDisabled: false,
}
