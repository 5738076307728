import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'

// Recursively remove empty keys of object, event for nested objects
export function removeEmptyKeysOfObject(obj) {
  if (!obj || isEmpty(obj)) return null

  // Delete keys containing empty strings or null values
  Object.keys(obj).forEach((key, index) => {
    if (obj[key] === null || obj[key] === '') delete obj[key]
    else if (isObject(obj[key]) && !isArray(obj[key])) {
      /* If we meet a nested object, two options:
       * If object is empty, remove it from form
       * Else return cleaned object
       */
      if (isEmpty(obj[key])) delete obj[key]
      else obj[key] = removeEmptyKeysOfObject(obj[key])

      // If object is empty after clean up, remove key
      if (isEmpty(obj[key])) delete obj[key]
    }
  })
  return obj
}
